import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Accordion,
  Spinner,
  ProgressBar,
  Card,
  Alert,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../Contexts/MyContext";
import { url, profit, discount } from "../../config";

const PcMasterGame = () => {
  const { user } = useAuth();

  const initialTime = 180; // 3 minutes in seconds
  const [highScore, setHighScore] = useState(0);

  const [totalPcBuilds, setTotalPcBuilds] = useState(0);
  const [successfulPcBuilds, setSuccessfulPcBuilds] = useState(0);
  const [reduction, setReduction] = useState(0);
  const [failedPcBuilds, setFailedPcBuilds] = useState(0);
  const [maxStreak, setMaxStreak] = useState(0);

  const [timer, setTimer] = useState(initialTime);
  const [lowerRange, setLowerRange] = useState(0);
  const [upperRange, setUpperRamge] = useState(0);
  const [score, setScore] = useState(0);
  const [multiplier, setMultiplier] = useState(1);
  const [startFlag, setStartFlag] = useState("N");
  const [isBlocked, setIsBlocked] = useState(false);
  const [result, setResult] = useState("");
  const msgRef = useRef(null);
  const [positionLower, setPositionLower] = useState(0);
  const [positionUpper, setPositionUpper] = useState(0);
  const [positionInput, setPositionInput] = useState(0);
  const pcRef = useRef(null);
  const [alt, setAlt] = useState("");
  const [showAlt, setShowAlt] = useState("");
  const [openKey, setOpenKey] = useState(["0"]);
  const [loading, setLoading] = useState(true);
  const [pcComplete, setPcComplete] = useState("");

  const [dbProcessors, setDbProcessors] = useState([]);
  const [dbRams, setDbRams] = useState([]);
  const [dbChipsets, setDbChipsets] = useState([]);
  const [dbGpus, setDbGpus] = useState([]);
  const [dbCoolers, setDbCoolers] = useState([]);
  const [dbStorages, setDbStorages] = useState([]);
  const [dbCabinets, setDbCabinets] = useState([]);
  const [dbPsus, setDbPsus] = useState([]);

  const [platforms, setPlatforms] = useState([]);
  const [gpuTypes, setGpuTypes] = useState([]);
  const [generations, setGenerations] = useState([]);
  const [processors, setProcessors] = useState([]);
  const [ramTypes, setRamTypes] = useState([]);
  const [ramRGBs, setRamRGBs] = useState([]);
  const rgbOption = [
    {
      id: "1",
      value: "Y",
      option: "Yes",
    },
    {
      id: "2",
      value: "N",
      option: "No",
    },
  ];
  const [ramSizes, setRamSizes] = useState([]);
  const [chipsets, setChipsets] = useState([]);
  const [gpuBrands, setGpuBrands] = useState([]);
  const [gpuModels, setGpuModels] = useState([]);
  const [coolers, setCoolers] = useState([]);
  const [primaryStorages, setPrimaryStorages] = useState([]);
  const [secondaryStorages, setSecondaryStorages] = useState([]);
  const [psus, setPsus] = useState([]);
  const [cabinetBrands, setCabinetBrands] = useState([]);
  const [cabinetModels, setCabinetModels] = useState([]);

  const [msg, setMsg] = useState("");
  const [count, setCount] = useState(0);
  const [message, setMessage] = useState("New budget will be allotted in...");

  const [pc, setPc] = useState({
    platform: "",
    gpuType: "",
    generation: "",
    processor: "",
    igpu: "",
    ramType: "",
    chipset: "",
    ramRGB: "",
    ramSize: "",
    gpuBrand: "",
    gpuModel: "",
    cooler: "",
    primaryStorage: "",
    secondaryStorage: "",
    psu: "",
    cabinetBrand: "",
    cabinetModel: "",
    price: 0,
    features: {
      processor: [],
      ram: [],
      chipset: [],
      gpu: [],
      cooler: [],
      psu: [],
      primaryStorage: [],
      secondaryStorage: [],
      cabinet: [],
    },
    tdp: {},
    benchmarks: {},
    connectivity: {},
    expansion: {},
    warranty: {},
  });

  const updateScore = async () => {
    try {
      if (!user || !user.userInfo || !user.userInfo[0]?.id) {
        console.error("User ID is missing");
        return;
      }

      await axios.post(
        `${url}/updateScore`,
        { id: user.userInfo[0].id, score, name: user.userInfo[0].firstname },
        { withCredentials: true }
      );
    } catch (err) {
      console.error("Error updating score:", err);
    }
  };

  useEffect(() => {
    if (timer > 0 && startFlag === "Y") {
      const interval = setTimeout(() => {
        setTimer((prev) => prev - 1);
      }, 1000);

      return () => clearTimeout(interval);
    }

    if (timer === 0) {
      window.scrollTo({ top: 0, behavior: "smooth" });

      if (score > highScore) {
        updateScore();
        setHighScore(score);
      }
    }
  }, [timer, startFlag]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchScore();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchScore = async () => {
    try {
      if (!user || !user.userInfo || !user.userInfo[0]?.id) {
        console.error("User ID is missing");
        return;
      }

      const res = await axios.post(
        `${url}/fetchScore`,
        { id: user.userInfo[0].id },
        { withCredentials: true }
      );

      if (res.data) {
        if (res.data.score) {
          setHighScore(res.data.score);
        }
      }
    } catch (err) {
      console.error("Error fetching score:", err);
    }
  };

  useEffect(() => {
    setMsg("");
    if (pc.platform === "") {
      fetchAll();
    }
    if (pc.cabinetModel !== "") {
      fetchPrice();
    }
  }, [pc.cabinetModel]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (pc.price > 0) {
      setTotalPcBuilds(totalPcBuilds + 1);
    }
    const extendedLower = lowerRange * 0.75;
    const extendedUpper = upperRange + (lowerRange - extendedLower);
    const range = extendedUpper - extendedLower;

    // Calculate positions for markers
    const priceFinal =
      (Math.round(pc.price / 500) * 500 - 10) * (1 - discount / 100);
    if (priceFinal > extendedUpper) {
      setPositionInput(100);
    } else if (priceFinal < extendedLower) {
      setPositionInput(0);
    } else {
      setPositionInput(((priceFinal - extendedLower) / range) * 100);
    }
    setPositionLower(((lowerRange - extendedLower) / range) * 100);
    setPositionUpper(((upperRange - extendedLower) / range) * 100);
    if (priceFinal <= upperRange && priceFinal >= lowerRange && pc.price > 0) {
      setResult("Passed");
      setSuccessfulPcBuilds(successfulPcBuilds + 1);
      setReduction(reduction + 0.5);
      setScore(score + 100 * multiplier);
      setMultiplier(multiplier + 1);
      if (multiplier > maxStreak) {
        setMaxStreak(multiplier);
      }
      setTimer(timer + 20);
      setMsg(
        <>
          Gained: <span className="text-danger">+{100 * multiplier}</span>{" "}
          &nbsp;&nbsp;&nbsp; Streak:{" "}
          <span className="text-danger">{multiplier}x</span>
        </>
      );
      if (msgRef.current) {
        msgRef.current.scrollIntoView({
          behavior: "smooth", // Smooth scroll effect
          block: "center", // Scroll to center of the viewport
        });
      }
      setMessage("New budget will be allotted in...");
      setCount(5);
    }

    if ((priceFinal > upperRange || priceFinal < lowerRange) && pc.price > 0) {
      setResult("Failed");
      setFailedPcBuilds(failedPcBuilds + 1);
      setMessage("PC not within budget! Try Again!");
      setMultiplier(1);
      setMsg(
        <>
          Gained: <span className="text-danger">0</span> &nbsp;&nbsp;&nbsp;
          Streak: <span className="text-danger">0</span>
        </>
      );
      if (msgRef.current) {
        msgRef.current.scrollIntoView({
          behavior: "smooth", // Smooth scroll effect
          block: "center", // Scroll to center of the viewport
        });
      }
    }
  }, [pc.price]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (count > 0) {
      setIsBlocked(true);
      const timer = setTimeout(() => setCount(count - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      generateBudgetRange(10 - reduction);
      setIsBlocked(false);
      if (result === "Failed") {
        setMessage("PC not within budegt! Try Again!");
      }
      setMessage("");
      setPc({
        platform: "",
        gpuType: "",
        generation: "",
        processor: "",
        igpu: "",
        ramType: "",
        chipset: "",
        ramRGB: "",
        ramSize: "",
        gpuBrand: "",
        gpuModel: "",
        cooler: "",
        primaryStorage: "",
        secondaryStorage: "",
        psu: "",
        cabinetBrand: "",
        cabinetModel: "",
        price: 0,
        features: {
          processor: [],
          ram: [],
          chipset: [],
          gpu: [],
          cooler: [],
          psu: [],
          primaryStorage: [],
          secondaryStorage: [],
          cabinet: [],
        },
        tdp: {},
        benchmarks: {},
        connectivity: {},
        expansion: {},
        warranty: {},
      });
      setOpenKey(["0"]);
      window.scrollTo({
        top: 0, // Scroll to the top
        behavior: "smooth", // Smooth scroll effect
      });
    }
  }, [count]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setShowAlt(false);
  }, [pc]);

  useEffect(() => {
    document.body.style.overscrollBehavior = "none";
    return () => {
      document.body.style.overscrollBehavior = "";
    };
  }, []);

  useEffect(() => {
    if (pcComplete === "Y" && pcRef.current) {
      const offset = 280; // Height of the header (adjust as needed)
      const elementPosition =
        pcRef.current.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
      setPcComplete("Z");
    }
    if (pc.platform === "") window.scrollTo(0, 0);
  }, [pc.price]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchAll = async () => {
    setLoading(true);
    try {
      const res = await axios.post(url + "/fetchAllComponents");
      setDbProcessors(res.data.processors);
      setDbRams(res.data.rams);
      setDbChipsets(res.data.chipsets);
      setDbGpus(res.data.gpus);
      setDbCoolers(res.data.coolers);
      setDbStorages(res.data.storages);
      setDbCabinets(res.data.cabinets);
      setDbPsus(res.data.psus);
      fetchPlatform(res.data.processors);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      window.scrollTo(0, 0);
    }
  };

  const fetchPlatform = (dbProcessors) => {
    setPlatforms(
      dbProcessors.filter((processor) => {
        return processor.type === "platform";
      })
    );
  };

  const changePlatform = (e) => {
    setPc({
      ...pc,
      platform: e.target.value,
      gpuType: "",
      generation: "",
      processor: "",
      igpu: "",
      ramType: "",
      chipset: "",
      ramRGB: "",
      ramSize: "",
      gpuBrand: "",
      gpuModel: "",
      cooler: "",
      primaryStorage: "",
      secondaryStorage: "",
      psu: "",
      cabinetBrand: "",
      cabinetModel: "",
      price: 0,
      features: {},
      tdp: {},
      benchmarks: {},
      connectivity: {},
      expansion: {},
      warranty: {},
    });
    setOpenKey(["0"]);
    setGpuTypes([]);
    setGenerations([]);
    setProcessors([]);
    setRamTypes([]);
    setRamRGBs([]);
    setRamSizes([]);
    setChipsets([]);
    setGpuBrands([]);
    setGpuModels([]);
    setCoolers([]);
    setPrimaryStorages([]);
    setSecondaryStorages([]);
    setPsus([]);
    setCabinetBrands([]);
    setCabinetModels([]);
    fetchGpuTypes(dbProcessors);
  };

  const fetchGpuTypes = (dbProcessors) => {
    setGpuTypes(
      dbProcessors.filter((processor) => {
        return processor.type === "gputype";
      })
    );
  };

  const changeGpuType = (e) => {
    setPc({
      ...pc,
      gpuType: e.target.value,
      generation: "",
      processor: "",
      igpu: "",
      ramType: "",
      chipset: "",
      ramRGB: "",
      ramSize: "",
      gpuBrand: "",
      gpuModel: "",
      cooler: "",
      primaryStorage: "",
      secondaryStorage: "",
      psu: "",
      cabinetBrand: "",
      cabinetModel: "",
      price: 0,
      features: {},
      tdp: {},
      benchmarks: {},
      connectivity: {},
      expansion: {},
      warranty: {},
    });
    setOpenKey(["0"]);
    setGenerations([]);
    setProcessors([]);
    setRamTypes([]);
    setChipsets([]);
    setRamRGBs([]);
    setRamSizes([]);
    setGpuBrands([]);
    setGpuModels([]);
    setCoolers([]);
    setPrimaryStorages([]);
    setSecondaryStorages([]);
    setPsus([]);
    setCabinetBrands([]);
    setCabinetModels([]);
    fetchGenerations(pc.platform, e.target.value);
  };

  const fetchGenerations = (platform, gpuType) => {
    let tempGenerations = [];
    dbProcessors
      .filter((processor) => {
        return (
          processor.type === "processor" &&
          processor.platform === platform &&
          processor.gputype === gpuType
        );
      })
      .forEach((processor) => {
        if (tempGenerations.indexOf(processor.series) < 0) {
          tempGenerations.push(processor.series);
        }
      });
    setGenerations(tempGenerations);
  };

  const changeGeneration = (e) => {
    setPc({
      ...pc,
      generation: e.target.value,
      processor: "",
      igpu: "",
      ramType: "",
      chipset: "",
      ramRGB: "",
      ramSize: "",
      gpuBrand: "",
      gpuModel: "",
      cooler: "",
      primaryStorage: "",
      secondaryStorage: "",
      psu: "",
      cabinetBrand: "",
      cabinetModel: "",
      price: 0,
      features: {},
      tdp: {},
      benchmarks: {},
      connectivity: {},
      expansion: {},
      warranty: {},
    });
    setOpenKey(["0"]);
    setProcessors([]);
    setRamTypes([]);
    setChipsets([]);
    setRamRGBs([]);
    setRamSizes([]);
    setGpuBrands([]);
    setGpuModels([]);
    setCoolers([]);
    setPrimaryStorages([]);
    setSecondaryStorages([]);
    setPsus([]);
    setCabinetBrands([]);
    setCabinetModels([]);
    fetchProcessors(pc.platform, pc.gpuType, e.target.value);
  };

  const fetchProcessors = (platform, gpuType, generation) => {
    setProcessors(
      dbProcessors.filter((processor) => {
        return (
          processor.platform === platform &&
          processor.gputype === gpuType &&
          processor.series === generation
        );
      })
    );
  };

  const changeProcessor = (e) => {
    const tempProcessor = dbProcessors.filter((pro) => {
      return pro.title === e.target.value;
    });
    setPc({
      ...pc,
      processor: e.target.value,
      igpu: tempProcessor[0].igpu,
      ramType: "",
      chipset: "",
      ramRGB: "",
      ramSize: "",
      gpuBrand: "",
      gpuModel: "",
      cooler: "",
      primaryStorage: "",
      secondaryStorage: "",
      psu: "",
      cabinetBrand: "",
      cabinetModel: "",
      price: 0,
      features: {
        processor: JSON.parse(tempProcessor[0].features),
        chipset: [],
        ram: [],
        gpu: [],
        cooler: [],
        primaryStorage: [],
        secondaryStorage: [],
        psu: [],
        cabinet: [],
      },
      tdp: { processorTdp: tempProcessor[0].maxtdp },
      benchmarks: {
        processorBenchmark: JSON.parse(tempProcessor[0].benchmark),
      },
      connectivity: {},
      expansion: {},
      warranty: { processor: JSON.parse(tempProcessor[0].warranty) },
    });
    setOpenKey(["1"]);
    setRamTypes([]);
    setChipsets([]);
    setRamRGBs([]);
    setRamSizes([]);
    setGpuBrands([]);
    setGpuModels([]);
    setCoolers([]);
    setPrimaryStorages([]);
    setSecondaryStorages([]);
    setPsus([]);
    setCabinetBrands([]);
    setCabinetModels([]);
    fetchRamTypes(e.target.value);
  };

  const fetchRamTypes = (processor) => {
    let tempRamTypes = [];
    const tempProcessor = dbProcessors.filter((pro) => {
      return pro.title === processor;
    });

    dbChipsets
      .filter((chipset) => {
        return (
          chipset.chipsetPlatform === tempProcessor[0].socket &&
          chipset.chipsetTdp >= tempProcessor[0].maxtdp
        );
      })
      .forEach((chipset) => {
        if (tempRamTypes.indexOf(chipset.chipsetRamType) < 0) {
          tempRamTypes.push(chipset.chipsetRamType);
        }
      });
    setRamTypes(tempRamTypes);
  };

  const changeRamType = (e) => {
    setPc({
      ...pc,
      ramType: e.target.value,
      chipset: "",
      ramRGB: "",
      ramSize: "",
      gpuBrand: "",
      gpuModel: "",
      cooler: "",
      primaryStorage: "",
      secondaryStorage: "",
      psu: "",
      cabinetBrand: "",
      cabinetModel: "",
      price: 0,
      features: {
        ...pc.features,
        chipset: [],
        ram: [],
        gpu: [],
        cooler: [],
        primaryStorage: [],
        secondaryStorage: [],
        psu: [],
        cabinet: [],
      },
      tdp: { ...pc.tdp },
      benchmarks: { ...pc.benchmarks },
      connectivity: {},
      expansion: {},
      warranty: { ...pc.warranty },
    });
    setOpenKey(["1"]);
    setChipsets([]);
    setRamRGBs([]);
    setRamSizes([]);
    setGpuBrands([]);
    setGpuModels([]);
    setCoolers([]);
    setPrimaryStorages([]);
    setSecondaryStorages([]);
    setPsus([]);
    setCabinetBrands([]);
    setCabinetModels([]);
    fetchChipsets(e.target.value);
  };

  const fetchChipsets = (ramType) => {
    const tempProcessor = dbProcessors.filter((pro) => {
      return pro.title === pc.processor;
    });
    setChipsets(
      dbChipsets.filter((chipset) => {
        return (
          chipset.chipsetPlatform === tempProcessor[0].socket &&
          chipset.chipsetRamType === ramType &&
          chipset.chipsetTdp >= tempProcessor[0].maxtdp
        );
      })
    );
  };

  const changeChipset = (e) => {
    const tempChipset = dbChipsets.filter((chipset) => {
      return (
        chipset.chipsetName === e.target.value &&
        chipset.chipsetRamType === pc.ramType
      );
    });
    setPc({
      ...pc,
      chipset: e.target.value,
      ramRGB: "",
      ramSize: "",
      gpuBrand: "",
      gpuModel: "",
      cooler: "",
      primaryStorage: "",
      secondaryStorage: "",
      psu: "",
      cabinetBrand: "",
      cabinetModel: "",
      price: 0,
      features: {
        ...pc.features,
        chipset: JSON.parse(tempChipset[0].features),
        ram: [],
        gpu: [],
        cooler: [],
        primaryStorage: [],
        secondaryStorage: [],
        psu: [],
        cabinet: [],
      },
      tdp: { ...pc.tdp },
      benchmarks: { ...pc.benchmarks },
      connectivity: {
        chipsetConnectivity: JSON.parse(tempChipset[0].connectivity),
        chipsetDisplay: JSON.parse(tempChipset[0].displayout),
      },
      expansion: {
        ...pc.expansion,
        chispetRamSlots: tempChipset[0].chipsetRamSlots,
        chipsetM2Slots: tempChipset[0].chipsetM2Slots,
        chipsetSataPorts: tempChipset[0].chipsetSataPorts,
      },
      warranty: {
        ...pc.warranty,
        chipset: JSON.parse(tempChipset[0].warranty),
      },
    });
    setRamRGBs([]);
    setRamSizes([]);
    setGpuBrands([]);
    setGpuModels([]);
    setCoolers([]);
    setPrimaryStorages([]);
    setSecondaryStorages([]);
    setPsus([]);
    setCabinetBrands([]);
    setCabinetModels([]);
    setOpenKey(["2"]);
    fetchRamRGBs();
  };

  const fetchRamRGBs = () => {
    setRamRGBs(rgbOption);
  };

  const changeRamRGB = (e) => {
    setPc({
      ...pc,
      ramRGB: e.target.value,
      ramSize: "",
      gpuBrand: "",
      gpuModel: "",
      cooler: "",
      primaryStorage: "",
      secondaryStorage: "",
      psu: "",
      cabinetBrand: "",
      cabinetModel: "",
      price: 0,
      features: {
        ...pc.features,
        ram: [],
        gpu: [],
        cooler: [],
        psu: [],
        primaryStorage: [],
        secondaryStorage: [],
        cabinet: [],
      },
      tdp: { ...pc.tdp },
      benchmarks: { ...pc.benchmarks },
      connectivity: { ...pc.connectivity },
      expansion: { ...pc.expansion },
      warranty: { ...pc.warranty },
    });
    setOpenKey(["2"]);
    setRamSizes([]);
    setGpuBrands([]);
    setGpuModels([]);
    setCoolers([]);
    setPrimaryStorages([]);
    setSecondaryStorages([]);
    setPsus([]);
    setCabinetBrands([]);
    setCabinetModels([]);
    fetchRamSizes(pc.expansion.chispetRamSlots, pc.ramType, e.target.value);
  };

  const fetchRamSizes = (chispetRamSlots, ramType, ramRGB) => {
    setRamSizes(
      dbRams.filter((ram) => {
        return (
          ram.ramType === ramType &&
          ram.ramRGB === ramRGB &&
          ram.ramCount <= chispetRamSlots
        );
      })
    );
  };

  const changeRamSize = (e) => {
    const tempRam = dbRams.filter((ram) => {
      return (
        ram.ramSize === e.target.value &&
        ram.ramRGB === pc.ramRGB &&
        ram.ramType === pc.ramType
      );
    });
    setPc({
      ...pc,
      ramSize: e.target.value,
      gpuBrand: "",
      gpuModel: "",
      cooler: "",
      primaryStorage: "",
      secondaryStorage: "",
      psu: "",
      cabinetBrand: "",
      cabinetModel: "",
      price: 0,
      features: {
        ...pc.features,
        ram: JSON.parse(tempRam[0].features),
        gpu: [],
        cooler: [],
        primaryStorage: [],
        secondaryStorage: [],
        psu: [],
        cabinet: [],
      },
      tdp: { ...pc.tdp },
      benchmarks: { ...pc.benchmarks },
      connectivity: { ...pc.connectivity },
      expansion: { ...pc.expansion, ramCount: tempRam[0].ramCount },
      warranty: { ...pc.warranty, ram: tempRam[0].warranty },
    });
    setGpuBrands([]);
    setGpuModels([]);
    setCoolers([]);
    setPrimaryStorages([]);
    setSecondaryStorages([]);
    setPsus([]);
    setCabinetBrands([]);
    setCabinetModels([]);
    setOpenKey(["3"]);
    fetchGpuBrands(pc.gpuType);
  };

  const fetchGpuBrands = (gpuType) => {
    let tempGpuBrands = [];
    let filteredGpuBrands = [];
    dbGpus.forEach((gpu) => {
      if (tempGpuBrands.indexOf(gpu.gpubrand) < 0) {
        tempGpuBrands.push(gpu.gpubrand);
      }
    });

    if (gpuType === "Dedicated") {
      filteredGpuBrands = tempGpuBrands.filter((gpuBrand) => {
        return gpuBrand !== "In-Built";
      });
      setGpuBrands(filteredGpuBrands);
    } else {
      setGpuBrands(tempGpuBrands);
    }
  };

  const changeGpuBrand = (e) => {
    setPc({
      ...pc,
      gpuBrand: e.target.value,
      gpuModel: "",
      cooler: "",
      primaryStorage: "",
      secondaryStorage: "",
      psu: "",
      cabinetBrand: "",
      cabinetModel: "",
      price: 0,
      features: {
        ...pc.features,
        gpu: [],
        cooler: [],
        primaryStorage: [],
        secondaryStorage: [],
        psu: [],
        cabinet: [],
      },
      tdp: { ...pc.tdp },
      benchmarks: { ...pc.benchmarks },
      connectivity: {
        ...pc.connectivity,
      },
      expansion: {
        ...pc.expansion,
      },
      warranty: { ...pc.warranty },
    });
    setOpenKey(["3"]);
    setGpuModels([]);
    setCoolers([]);
    setPrimaryStorages([]);
    setSecondaryStorages([]);
    setPsus([]);
    setCabinetBrands([]);
    setCabinetModels([]);
    fetchGpuModels(e.target.value);
  };

  const fetchGpuModels = (gpuBrand) => {
    setGpuModels(
      dbGpus.filter((gpu) => {
        return gpu.gpubrand === gpuBrand;
      })
    );
  };

  const changeGpuModel = (e) => {
    const tempGpu = dbGpus.filter((gpu) => {
      return (
        gpu.gpumodel ===
        (pc.gpuBrand !== "In-Built" ? e.target.value : "In-Built")
      );
    });
    setPc({
      ...pc,
      gpuModel: e.target.value,
      cooler: "",
      primaryStorage: "",
      secondaryStorage: "",
      psu: "",
      cabinetBrand: "",
      cabinetModel: "",
      price: 0,
      features: {
        ...pc.features,
        gpu: JSON.parse(tempGpu[0].features),
        cooler: [],
        psu: [],
        primaryStorage: [],
        secondaryStorage: [],
        cabinet: [],
      },
      tdp: { ...pc.tdp, gpuTdp: tempGpu[0].maxtdp },
      benchmarks: {
        ...pc.benchmarks,
        gpuBenchmark: JSON.parse(tempGpu[0].benchmarks),
      },
      connectivity: {
        ...pc.connectivity,
        gpuDisplay: JSON.parse(tempGpu[0].displayout),
      },
      expansion: {
        ...pc.expansion,
      },
      warranty: { ...pc.warranty, gpu: tempGpu[0].warranty },
    });
    setCoolers([]);
    setPrimaryStorages([]);
    setSecondaryStorages([]);
    setPsus([]);
    setCabinetBrands([]);
    setCabinetModels([]);
    setOpenKey(["4"]);
    fetchCoolers(pc.processor);
  };

  const fetchCoolers = (processor) => {
    const tempProcessor = dbProcessors.filter((pro) => {
      return pro.title === processor;
    });
    if (tempProcessor[0].cooler === "Box") {
      setCoolers(
        dbCoolers.filter((cooler) => {
          return cooler.maxtdp > pc.tdp.processorTdp;
        })
      );
    } else {
      setCoolers(
        dbCoolers.filter((cooler) => {
          return (
            cooler.coolername !== "Box Cooler" &&
            cooler.maxtdp >= pc.tdp.processorTdp
          );
        })
      );
    }
  };

  const changeCooler = (e) => {
    const tempCooler = dbCoolers.filter((cooler) => {
      return cooler.coolername === e.target.value;
    });
    setPc({
      ...pc,
      cooler: e.target.value,
      primaryStorage: "",
      secondaryStorage: "",
      psu: "",
      cabinetBrand: "",
      cabinetModel: "",
      price: 0,
      features: {
        ...pc.features,
        cooler: JSON.parse(tempCooler[0].features),
        psu: [],
        primaryStorage: [],
        secondaryStorage: [],
        cabinet: [],
      },
      tdp: { ...pc.tdp },
      benchmarks: { ...pc.benchmarks },
      connectivity: {
        ...pc.connectivity,
      },
      expansion: {
        ...pc.expansion,
      },
      warranty: { ...pc.warranty, cooler: tempCooler[0].warranty },
    });
    setPrimaryStorages([]);
    setSecondaryStorages([]);
    setPsus([]);
    setCabinetBrands([]);
    setCabinetModels([]);
    setOpenKey(["5"]);
    fetchPrimaryStorage();
  };

  const fetchPrimaryStorage = () => {
    setPrimaryStorages(
      dbStorages.filter((storage) => {
        return (
          storage.storagename !== "Not Available" &&
          storage.storagetype === "m.2"
        );
      })
    );
  };

  const changePrimaryStorage = (e) => {
    const tempPrimaryStorage = dbStorages.filter((storage) => {
      return storage.storagename === e.target.value;
    });
    setPc({
      ...pc,
      primaryStorage: e.target.value,
      secondaryStorage: "",
      psu: "",
      cabinetBrand: "",
      cabinetModel: "",
      price: 0,
      features: {
        ...pc.features,
        psu: [],
        primaryStorage: JSON.parse(tempPrimaryStorage[0].features),
        secondaryStorage: [],
        cabinet: [],
      },
      tdp: { ...pc.tdp },
      benchmarks: { ...pc.benchmarks },
      connectivity: {
        ...pc.connectivity,
      },
      expansion: {
        ...pc.expansion,
        psPort: tempPrimaryStorage[0].storagetype,
      },
      warranty: {
        ...pc.warranty,
        primaryStorage: tempPrimaryStorage[0].warranty,
      },
    });
    setOpenKey(["5"]);
    setSecondaryStorages([]);
    setPsus([]);
    setCabinetBrands([]);
    setCabinetModels([]);
    fetchSecondaryStorage();
  };

  const fetchSecondaryStorage = () => {
    pc.expansion.chipsetM2Slots > 1
      ? setSecondaryStorages(dbStorages)
      : setSecondaryStorages(
          dbStorages.filter((storage) => {
            return storage.storagetype !== "m.2";
          })
        );
  };

  const changeSecondaryStorage = (e) => {
    const tempSecondaryStorage = dbStorages.filter((storage) => {
      return storage.storagename === e.target.value;
    });
    setPc({
      ...pc,
      secondaryStorage: e.target.value,
      psu: "",
      cabinetBrand: "",
      cabinetModel: "",
      price: 0,
      features: {
        ...pc.features,
        psu: [],
        secondaryStorage: JSON.parse(tempSecondaryStorage[0].features),
        cabinet: [],
      },
      tdp: { ...pc.tdp },
      benchmarks: { ...pc.benchmarks },
      connectivity: {
        ...pc.connectivity,
      },
      expansion: {
        ...pc.expansion,
        ssPort: tempSecondaryStorage[0].storagetype,
      },
      warranty: {
        ...pc.warranty,
        secondaryStorage: tempSecondaryStorage[0].warranty,
      },
    });
    setPsus([]);
    setCabinetBrands([]);
    setCabinetModels([]);
    setOpenKey(["6"]);
    fetchPsu();
  };

  const fetchPsu = () => {
    setPsus(
      dbPsus.filter((psu) => {
        return psu.tdp > (pc.tdp.processorTdp + pc.tdp.gpuTdp) * 1.25 + 100;
      })
    );
  };

  const changePsu = (e) => {
    const tempPsu = dbPsus.filter((psu) => {
      return psu.psuname === e.target.value;
    });
    setPc({
      ...pc,
      psu: e.target.value,
      cabinetBrand: "",
      cabinetModel: "",
      price: 0,
      features: {
        ...pc.features,
        psu: JSON.parse(tempPsu[0].features),
        cabinet: [],
      },
      tdp: { ...pc.tdp },
      benchmarks: { ...pc.benchmarks },
      connectivity: {
        ...pc.connectivity,
      },
      expansion: {
        ...pc.expansion,
      },
      warranty: { ...pc.warranty, psu: tempPsu[0].warranty },
    });
    setCabinetBrands([]);
    setCabinetModels([]);
    setOpenKey(["7"]);
    fetchCabinetBrands();
  };

  const fetchCabinetBrands = () => {
    let tempCabinetBrands = [];
    const tempChipset = dbChipsets.filter((chipset) => {
      return (
        chipset.chipsetName === pc.chipset &&
        chipset.chipsetRamType === pc.ramType
      );
    });
    const tempGpu = dbGpus.filter((gpu) => {
      return gpu.gpumodel === pc.gpuModel;
    });
    const tempCooler = dbCoolers.filter((cooler) => {
      return cooler.coolername === pc.cooler;
    });
    dbCabinets
      .filter((cabinet) => {
        return (
          cabinet.cabinetchipset >= tempChipset[0].chipsetSize &&
          cabinet.cabinetvga >= (tempGpu.length > 0 ? tempGpu[0].length : 0) &&
          cabinet.cabinetcooler >= tempCooler[0].coolersize
        );
      })
      .map((cabinet) => {
        if (tempCabinetBrands.indexOf(cabinet.cabinetbrand) < 0) {
          tempCabinetBrands.push(cabinet.cabinetbrand);
        }
        return cabinet;
      });
    setCabinetBrands(tempCabinetBrands);
  };

  const changeCabinetBrand = (e) => {
    setPc({
      ...pc,
      cabinetBrand: e.target.value,
      cabinetModel: "",
      price: 0,
      features: {
        ...pc.features,
        cabinet: [],
      },
      tdp: { ...pc.tdp },
      benchmarks: { ...pc.benchmarks },
      connectivity: {
        ...pc.connectivity,
      },
      expansion: {
        ...pc.expansion,
      },
      warranty: { ...pc.warranty },
    });
    setOpenKey(["7"]);
    setCabinetModels([]);
    fetchCabinetModels(e.target.value);
  };

  const fetchCabinetModels = (cabinetBrand) => {
    const tempChipset = dbChipsets.filter((chipset) => {
      return (
        chipset.chipsetName === pc.chipset &&
        chipset.chipsetRamType === pc.ramType
      );
    });
    const tempGpu = dbGpus.filter((gpu) => {
      return gpu.gpumodel === pc.gpuModel;
    });
    const tempCooler = dbCoolers.filter((cooler) => {
      return cooler.coolername === pc.cooler;
    });
    setCabinetModels(
      dbCabinets.filter((cabinet) => {
        return (
          cabinet.cabinetchipset >= tempChipset[0].chipsetSize &&
          cabinet.cabinetvga >= (tempGpu.length > 0 ? tempGpu[0].length : 0) &&
          cabinet.cabinetcooler >= tempCooler[0].coolersize &&
          cabinet.cabinetbrand === cabinetBrand
        );
      })
    );
  };

  const changeCabinetModel = (e) => {
    const tempCabinet = dbCabinets.filter((cabinet) => {
      return (
        cabinet.cabinetbrand === pc.cabinetBrand &&
        cabinet.cabinetmodel === e.target.value
      );
    });
    setPc({
      ...pc,
      cabinetModel: e.target.value,
      price: 0,
      features: {
        ...pc.features,
        cabinet: JSON.parse(tempCabinet[0].features),
      },
      tdp: { ...pc.tdp },
      benchmarks: { ...pc.benchmarks },
      connectivity: {
        ...pc.connectivity,
        cabinetConnectivity: JSON.parse(tempCabinet[0].connectivity),
      },
      expansion: {
        ...pc.expansion,
        cabinetSata: JSON.parse(tempCabinet[0].expansion),
      },
      warranty: { ...pc.warranty, cabinet: tempCabinet[0].warranty },
    });
    setPcComplete("Y");
    setOpenKey([]);
  };

  const fetchPrice = () => {
    let price = 0;
    dbProcessors
      .filter((processor) => {
        return processor.title === pc.processor;
      })
      .forEach((processor) => {
        price = price + processor.price;
      });
    dbRams
      .filter((ram) => {
        return (
          ram.ramSize === pc.ramSize &&
          ram.ramRGB === pc.ramRGB &&
          ram.ramType === pc.ramType
        );
      })
      .forEach((ram) => {
        price = price + ram.ramPrice;
      });
    dbChipsets
      .filter((chipset) => {
        return (
          chipset.chipsetName === pc.chipset &&
          chipset.chipsetRamType === pc.ramType
        );
      })
      .forEach((chipset) => {
        price = price + chipset.chipsetPrice;
      });
    dbGpus
      .filter((gpu) => {
        return gpu.gpumodel === pc.gpuModel;
      })
      .forEach((gpu) => {
        price = price + gpu.price;
      });
    dbCoolers
      .filter((cooler) => {
        return cooler.coolername === pc.cooler;
      })
      .forEach((cooler) => {
        price = price + cooler.price;
      });
    dbStorages
      .filter((storage) => {
        return storage.storagename === pc.primaryStorage;
      })
      .forEach((storage) => {
        price = price + storage.price;
      });
    dbStorages
      .filter((storage) => {
        return storage.storagename === pc.secondaryStorage;
      })
      .forEach((storage) => {
        price = price + storage.price;
      });
    dbPsus
      .filter((psu) => {
        return psu.psuname === pc.psu;
      })
      .forEach((psu) => {
        price = price + psu.price;
      });
    dbCabinets
      .filter((cabinet) => {
        return (
          cabinet.cabinetbrand === pc.cabinetBrand &&
          cabinet.cabinetmodel === pc.cabinetModel
        );
      })
      .forEach((cabinet) => {
        price = price + cabinet.price;
      });

    price = (1 + profit / 100) * price;

    setPc({ ...pc, price });
  };

  const handleAccordion = (eventKey) => {
    const requiredFields = {
      1: "processor",
      2: "chipset",
      3: "ramSize",
      4: "gpuModel",
      5: "cooler",
      6: "secondaryStorage",
      7: "psu",
    };
    const displayFilelds = {
      1: "Processor",
      2: "Chipset",
      3: "RAM",
      4: "GPU",
      5: "Cooler",
      6: "Storage",
      7: "Power Supply",
    };
    const greatest = Math.max(...openKey.map(Number));
    if (requiredFields[eventKey] && pc[requiredFields[eventKey]] === "") {
      setAlt(displayFilelds[greatest + 1]);
      setShowAlt(true);
    } else {
      const index = openKey.indexOf(eventKey);
      index === -1
        ? setOpenKey([...openKey, eventKey])
        : setOpenKey(
            openKey.filter(function (item) {
              return item !== eventKey;
            })
          );
    }
  };

  const generateBudgetRange = (percentage) => {
    const minValue = 30000;
    const maxValue = 350000;
    const baseValue =
      Math.floor(Math.random() * (maxValue - minValue + 1)) + minValue;
    const lowerBound =
      Math.round((baseValue * (1 - percentage / 100)) / 1000) * 1000;
    const upperBound =
      Math.round((baseValue * (1 + percentage / 100)) / 1000) * 1000;

    setLowerRange(lowerBound);
    setUpperRamge(upperBound);
  };

  return (
    <Container>
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          {/* React Bootstrap Spinner */}
          <Spinner animation="grow" variant="danger">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <Container>
          <Row className="justify-content-center my-5">
            {showAlt ? (
              <Alert
                dismissible
                className="text-center"
                onClose={() => setShowAlt(false)}
                variant="dark"
                style={{
                  width: "400px",
                  position: "fixed",
                  top: "20px", // Adjust this as needed to control vertical position
                  left: "50%",
                  transform: "translateX(-50%)",
                  zIndex: 1050,
                }}
              >
                Please complete{" "}
                {alt ? (
                  <>
                    <span className="text-danger">{alt}</span>
                    <span> section </span>
                  </>
                ) : (
                  "preceding sections "
                )}
                first!
              </Alert>
            ) : (
              ""
            )}
            <Col
              xxl={8}
              lg={12}
              className="border border-dark rounded"
              style={{ backgroundColor: "#151515" }}
            >
              {startFlag === "Y" ? (
                <>
                  {window.innerWidth > 500 ? (
                    <h1 className="my-4 text-center text-danger">
                      PC Master Hunt
                    </h1>
                  ) : (
                    ""
                  )}
                  {timer > 0 && (
                    <Row
                      className="justify-content-center fixed-top bg-black text-light py-3 shadow-lg"
                      style={{ zIndex: 1050 }}
                    >
                      <Col md={8} lg={8} xs={12} className="text-center">
                        <Row className="justify-content-center border border-light rounded mx-4 p-3">
                          {/* Score and Timer */}
                          <Row
                            xxl={8}
                            xs={12}
                            className="text-danger text-center mb-2"
                          >
                            <Col xxl={6} xs={6} className="fs-4">
                              <span className="text-light fs-5">Score:</span>{" "}
                              {score}
                            </Col>
                            <Col xxl={6} xs={6} className="fs-4">
                              <span className="text-light fs-5">Time:</span>{" "}
                              {Math.floor(timer / 60)}:
                              {String(timer % 60).padStart(2, "0")}
                            </Col>
                          </Row>

                          {/* Progress Bar */}
                          <Col xxl={12} className="mb-2">
                            <ProgressBar
                              style={{ height: "5px" }}
                              variant="danger"
                              now={(timer / initialTime) * 100}
                            />
                          </Col>

                          {/* Budget */}
                          <Col xxl={12} className="text-danger fs-5">
                            <span className="text-light">Budget:</span> ₹
                            {lowerRange.toLocaleString("en-IN")} - ₹
                            {upperRange.toLocaleString("en-IN")}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  )}
                  {isBlocked && (
                    <div
                      className="position-absolute top-0 start-0 w-100 h-100"
                      style={{
                        background: "rgba(255, 255, 255, 0)", //Transparent background
                        zIndex: 1000, // Ensure overlay is above other content
                        pointerEvents: "all", // Blocks all clicks
                      }}
                    ></div>
                  )}
                  {timer > 0 ? (
                    <Row className="justify-content-center mb-5">
                      <Col
                        md={10}
                        lg={8}
                        xs={12}
                        style={{ backgroundColor: "#151515" }}
                      >
                        <Form>
                          <Accordion
                            activeKey={openKey}
                            onSelect={handleAccordion}
                          >
                            <Accordion.Item eventKey="0">
                              <Accordion.Header>
                                {pc.processor ? (
                                  <span className="text-light">
                                    Processor :{" "}
                                    <span className="text-danger">
                                      {pc.processor}
                                    </span>
                                  </span>
                                ) : (
                                  <span className="text-light">Processor</span>
                                )}
                              </Accordion.Header>
                              <Accordion.Body>
                                <Form.Group controlId="platform">
                                  <Form.Label>Platform</Form.Label>
                                  <Form.Control
                                    size="sm"
                                    className="mb-3 dark-select"
                                    as="select"
                                    value={pc.platform}
                                    onChange={changePlatform}
                                  >
                                    <option hidden>Choose any one...</option>
                                    {platforms.map((ctr) => (
                                      <option
                                        key={ctr.idcomp}
                                        value={ctr.title}
                                      >
                                        {ctr.title}
                                      </option>
                                    ))}
                                  </Form.Control>
                                </Form.Group>
                                <Form.Group controlId="gpuType">
                                  <Form.Label>GPU Type</Form.Label>
                                  <Form.Control
                                    size="sm"
                                    className="mb-3 dark-select"
                                    as="select"
                                    value={pc.gpuType}
                                    onChange={changeGpuType}
                                  >
                                    <option hidden>Choose any one...</option>
                                    {gpuTypes.map((ctr) => (
                                      <option
                                        key={ctr.idcomp}
                                        value={ctr.title}
                                      >
                                        {ctr.title}
                                      </option>
                                    ))}
                                  </Form.Control>
                                </Form.Group>
                                <Form.Group controlId="generations">
                                  <Form.Label>Processor Series</Form.Label>
                                  <Form.Control
                                    size="sm"
                                    className="mb-3 dark-select"
                                    as="select"
                                    value={pc.generation}
                                    onChange={changeGeneration}
                                  >
                                    <option hidden>Choose any one...</option>
                                    {generations.map((title, index) => (
                                      <option key={index} value={title}>
                                        {title}
                                      </option>
                                    ))}
                                  </Form.Control>
                                </Form.Group>
                                <Form.Group controlId="processor">
                                  <Form.Label>Processor Model</Form.Label>
                                  <Form.Control
                                    size="sm"
                                    className="mb-3 dark-select"
                                    as="select"
                                    value={pc.processor}
                                    onChange={changeProcessor}
                                  >
                                    <option hidden>Choose any one...</option>
                                    {processors.map((ctr) => (
                                      <option
                                        key={ctr.idcomp}
                                        value={ctr.title}
                                      >
                                        {ctr.title}
                                      </option>
                                    ))}
                                  </Form.Control>
                                </Form.Group>
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                              <Accordion.Header>
                                {pc.chipset ? (
                                  <span className="text-light">
                                    Chipset :{" "}
                                    <span className="text-danger">
                                      {pc.chipset}
                                    </span>
                                  </span>
                                ) : (
                                  <span className="text-light">Chipset</span>
                                )}
                              </Accordion.Header>
                              <Accordion.Body>
                                <Form.Group controlId="chipset">
                                  <Form.Group controlId="ramType">
                                    <Form.Label>RAM Type</Form.Label>
                                    <Form.Control
                                      size="sm"
                                      className="mb-3 dark-select"
                                      as="select"
                                      value={pc.ramType}
                                      onChange={changeRamType}
                                    >
                                      <option hidden>Choose any one...</option>
                                      {ramTypes.map((title, index) => (
                                        <option key={index} value={title}>
                                          {title}
                                        </option>
                                      ))}
                                    </Form.Control>
                                  </Form.Group>
                                  <Form.Label>Chipset Model</Form.Label>
                                  <Form.Control
                                    size="sm"
                                    className="mb-3 dark-select"
                                    as="select"
                                    value={pc.chipset}
                                    onChange={changeChipset}
                                  >
                                    <option hidden>Choose any one...</option>
                                    {chipsets.map((ctr) => (
                                      <option
                                        key={ctr.idchipset}
                                        value={ctr.chipsetName}
                                      >
                                        {ctr.chipsetName}
                                      </option>
                                    ))}
                                  </Form.Control>
                                </Form.Group>
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                              <Accordion.Header>
                                {pc.ramSize ? (
                                  <span className="text-light">
                                    RAM :{" "}
                                    <span className="text-danger">
                                      {pc.ramSize}
                                    </span>
                                  </span>
                                ) : (
                                  <span className="text-light">RAM</span>
                                )}
                              </Accordion.Header>
                              <Accordion.Body>
                                <Form.Group controlId="ramRGB">
                                  <Form.Label>RAM RGB</Form.Label>
                                  <Form.Control
                                    size="sm"
                                    className="mb-3 dark-select"
                                    as="select"
                                    value={pc.ramRGB}
                                    onChange={changeRamRGB}
                                  >
                                    <option hidden>Choose any one...</option>
                                    {ramRGBs.map((ctr) => (
                                      <option key={ctr.id} value={ctr.value}>
                                        {ctr.option}
                                      </option>
                                    ))}
                                  </Form.Control>
                                </Form.Group>
                                <Form.Group controlId="ramSize">
                                  <Form.Label>RAM Size</Form.Label>
                                  <Form.Control
                                    size="sm"
                                    className="mb-3 dark-select"
                                    as="select"
                                    value={pc.ramSize}
                                    onChange={changeRamSize}
                                  >
                                    <option hidden>Choose any one...</option>
                                    {ramSizes.map((ctr) => (
                                      <option
                                        key={ctr.idram}
                                        value={ctr.ramSize}
                                      >
                                        {ctr.ramSize}
                                      </option>
                                    ))}
                                  </Form.Control>
                                </Form.Group>
                              </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="3">
                              <Accordion.Header>
                                {pc.gpuModel ? (
                                  <span className="text-light">
                                    GPU :{" "}
                                    <span className="text-danger">
                                      {pc.gpuBrand === "In-Built"
                                        ? pc.igpu
                                        : pc.gpuModel}
                                    </span>
                                  </span>
                                ) : (
                                  <span className="text-light">GPU</span>
                                )}
                              </Accordion.Header>
                              <Accordion.Body>
                                <Form.Group controlId="gpuBrand">
                                  <Form.Label>GPU Brand</Form.Label>
                                  <Form.Control
                                    size="sm"
                                    as="select"
                                    className="mb-3 dark-select"
                                    value={pc.gpuBrand}
                                    onChange={changeGpuBrand}
                                  >
                                    <option hidden>Choose any one...</option>
                                    {gpuBrands.map((title, index) => (
                                      <option key={index} value={title}>
                                        {title}
                                      </option>
                                    ))}
                                  </Form.Control>
                                </Form.Group>
                                <Form.Group controlId="gpuModel">
                                  <Form.Label>GPU Model</Form.Label>
                                  <Form.Control
                                    size="sm"
                                    className="mb-3 dark-select"
                                    as="select"
                                    value={pc.gpuModel}
                                    onChange={changeGpuModel}
                                  >
                                    <option hidden>Choose any one...</option>
                                    {pc.gpuBrand !== "In-Built" ? (
                                      gpuModels.map((ctr) => (
                                        <option
                                          key={ctr.idgpu}
                                          value={ctr.gpumodel}
                                        >
                                          {ctr.gpumodel}
                                        </option>
                                      ))
                                    ) : (
                                      <option key={1} value={pc.igpu}>
                                        {pc.igpu}
                                      </option>
                                    )}
                                  </Form.Control>
                                </Form.Group>
                              </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="4">
                              <Accordion.Header>
                                {pc.cooler ? (
                                  <span className="text-light">
                                    Cooler :{" "}
                                    <span className="text-danger">
                                      {pc.cooler}
                                    </span>
                                  </span>
                                ) : (
                                  <span className="text-light">Cooler</span>
                                )}
                              </Accordion.Header>
                              <Accordion.Body>
                                <Form.Group controlId="cooler">
                                  <Form.Label>Cooler Model</Form.Label>
                                  <Form.Control
                                    size="sm"
                                    className="mb-3 dark-select"
                                    as="select"
                                    value={pc.cooler}
                                    onChange={changeCooler}
                                  >
                                    <option hidden>Choose any one...</option>
                                    {coolers.map((ctr) => (
                                      <option
                                        key={ctr.idcooler}
                                        value={ctr.coolername}
                                      >
                                        {ctr.coolername}
                                      </option>
                                    ))}
                                  </Form.Control>
                                </Form.Group>
                              </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="5">
                              <Accordion.Header>
                                {pc.primaryStorage || pc.secondaryStorage ? (
                                  <span className="text-light">
                                    Storage :{" "}
                                    <span className="text-danger">
                                      {pc.primaryStorage
                                        ? pc.primaryStorage
                                        : ""}{" "}
                                      {pc.secondaryStorage === "" ||
                                      pc.secondaryStorage === "Not Available"
                                        ? ""
                                        : "+ " + pc.secondaryStorage}
                                    </span>
                                  </span>
                                ) : (
                                  <span className="text-light">Storage</span>
                                )}
                              </Accordion.Header>
                              <Accordion.Body>
                                <Form.Group controlId="primaryStorage">
                                  <Form.Label>Primary Storage</Form.Label>
                                  <Form.Control
                                    size="sm"
                                    className="mb-3 dark-select"
                                    as="select"
                                    value={pc.primaryStorage}
                                    onChange={changePrimaryStorage}
                                  >
                                    <option hidden>Choose any one...</option>
                                    {primaryStorages.map((ctr, i) => (
                                      <option
                                        key={"primary" + i}
                                        value={ctr.storagename}
                                      >
                                        {ctr.storagename}
                                      </option>
                                    ))}
                                  </Form.Control>
                                </Form.Group>
                                <Form.Group controlId="secondaryStorage">
                                  <Form.Label>Secondary Storage</Form.Label>
                                  <Form.Control
                                    size="sm"
                                    className="mb-3 dark-select"
                                    as="select"
                                    value={pc.secondaryStorage}
                                    onChange={changeSecondaryStorage}
                                  >
                                    <option hidden>Choose any one...</option>
                                    {secondaryStorages.map((ctr, i) => (
                                      <option
                                        key={"secondary" + i}
                                        value={ctr.storagename}
                                      >
                                        {ctr.storagename}
                                      </option>
                                    ))}
                                  </Form.Control>
                                </Form.Group>
                              </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="6">
                              <Accordion.Header>
                                {pc.psu ? (
                                  <span className="text-light">
                                    Power Supply :{" "}
                                    <span className="text-danger">
                                      {pc.psu}
                                    </span>
                                  </span>
                                ) : (
                                  <span className="text-light">
                                    Power Supply
                                  </span>
                                )}
                              </Accordion.Header>
                              <Accordion.Body>
                                <Form.Group controlId="psu">
                                  <Form.Label>PSU Model</Form.Label>
                                  <Form.Control
                                    size="sm"
                                    className="mb-3 dark-select"
                                    as="select"
                                    value={pc.psu}
                                    onChange={changePsu}
                                  >
                                    <option hidden>Choose any one...</option>
                                    {psus.map((ctr) => (
                                      <option
                                        key={ctr.idpsu}
                                        value={ctr.psuname}
                                      >
                                        {ctr.psuname}
                                      </option>
                                    ))}
                                  </Form.Control>
                                </Form.Group>
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="7">
                              <Accordion.Header>
                                {pc.cabinetModel ? (
                                  <span className="text-light">
                                    Cabinet :{" "}
                                    <span className="text-danger">
                                      {pc.cabinetBrand} {pc.cabinetModel}
                                    </span>
                                  </span>
                                ) : (
                                  <span className="text-light">Cabinet</span>
                                )}
                              </Accordion.Header>
                              <Accordion.Body>
                                <Form.Group controlId="cabinetBrand">
                                  <Form.Label>Cabinet Brand</Form.Label>
                                  <Form.Control
                                    size="sm"
                                    className="mb-3 dark-select"
                                    as="select"
                                    value={pc.cabinetBrand}
                                    onChange={changeCabinetBrand}
                                  >
                                    <option hidden>Choose any one...</option>
                                    {cabinetBrands.map((ctr, index) => (
                                      <option key={index} value={ctr}>
                                        {ctr}
                                      </option>
                                    ))}
                                  </Form.Control>
                                </Form.Group>
                                <Form.Group controlId="cabinetModel">
                                  <Form.Label>Cabinet Model</Form.Label>
                                  <Form.Control
                                    className="mb-3 dark-select"
                                    size="sm"
                                    as="select"
                                    value={pc.cabinetModel}
                                    onChange={changeCabinetModel}
                                  >
                                    <option hidden>Choose any one...</option>
                                    {cabinetModels.map((ctr, index) => (
                                      <option
                                        key={index}
                                        value={ctr.cabinetmodel}
                                      >
                                        {ctr.cabinetmodel}
                                      </option>
                                    ))}
                                  </Form.Control>
                                </Form.Group>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        </Form>
                      </Col>
                      <Col
                        xxl={12}
                        ref={msgRef}
                        className="text-center text-light mt-3"
                      >
                        {msg}
                      </Col>
                      {pc.price > 0 ? (
                        <Col lg={8} className="mt-3">
                          <div className="p-3">
                            {/* Extended Budget Labels */}
                            <div
                              className="position-relative"
                              style={{ height: "30px" }}
                            >
                              {/* Extended Budget Range Bar */}
                              <ProgressBar
                                now={100}
                                variant="danger"
                                style={{ height: "10px", borderRadius: "5px" }}
                              />

                              {/* Lower Budget Marker */}
                              {/* Always Visible Label */}
                              <div
                                className="position-absolute text-dark bg-light px-2 py-1 rounded border"
                                style={{
                                  fontSize: "12px",
                                  top: "20px", // Position above the marker
                                  left: `calc(${positionLower}% - 25px)`, // Center the label
                                  whiteSpace: "nowrap",
                                }}
                              >
                                ₹{lowerRange.toLocaleString("en-IN")}
                              </div>

                              {/* Lower Budget Marker */}
                              <div
                                className="position-absolute bg-light rounded-circle"
                                style={{
                                  width: "12px",
                                  height: "12px",
                                  top: "-1px",
                                  left: `calc(${positionLower}% - 6px)`,
                                }}
                              ></div>

                              {/* Upper Budget Marker */}
                              {/* Always Visible Label */}
                              <div
                                className="position-absolute text-dark bg-light px-2 py-1 rounded border"
                                style={{
                                  fontSize: "12px",
                                  top: "20px", // Position above the marker
                                  left: `calc(${positionUpper}% - 25px)`, // Center the label
                                  whiteSpace: "nowrap",
                                }}
                              >
                                ₹{upperRange.toLocaleString("en-IN")}
                              </div>

                              {/* Upper Budget Marker */}
                              <div
                                className="position-absolute bg-light rounded-circle"
                                style={{
                                  width: "12px",
                                  height: "12px",
                                  top: "-1px",
                                  left: `calc(${positionUpper}% - 6px)`,
                                }}
                              ></div>

                              {/* Input Budget Marker */}
                              <div
                                className="position-absolute text-white bg-dark px-2 py-1 rounded"
                                style={{
                                  fontSize: "12px",
                                  top: "-30px", // Position below the marker
                                  left: `calc(${positionInput}% - 25px)`, // Center the label
                                  whiteSpace: "nowrap",
                                }}
                              >
                                Your PC: ₹
                                {(
                                  (Math.round(pc.price / 500) * 500 - 10) *
                                  (1 - discount / 100)
                                ).toLocaleString("en-IN")}
                              </div>

                              {/* Marker */}
                              <div
                                className="position-absolute bg-black rounded-circle"
                                style={{
                                  width: "14px",
                                  height: "14px",
                                  top: "-2px",
                                  left: `calc(${positionInput}% - 7px)`, // Center marker
                                }}
                              ></div>
                            </div>
                          </div>
                        </Col>
                      ) : (
                        ""
                      )}
                      <Col xxl={12} className="text-center text-secondary mt-3">
                        <div className="text-center mt-3">
                          <h5 className="text-danger">{message}</h5>
                          {count > 0 && (
                            <h2 className="text-danger fw-bold">{count}</h2>
                          )}
                        </div>
                      </Col>
                      <Col xxl={12} className="text-center text-secondary mt-3">
                        <small>
                          Need Assistance?{" "}
                          <Link className="link-danger" to="tel:+91-6369933507">
                            +91-6369933507
                          </Link>
                        </small>
                      </Col>
                    </Row>
                  ) : (
                    <Col className="text-light text-center border-danger border my-5 rounded">
                      <h3 className="text-danger my-3">Game Over!</h3>
                      <Col>
                        <strong>Total PCs Built:</strong> {totalPcBuilds}
                      </Col>
                      <Col>
                        <strong>Sucessful Builds:</strong> {successfulPcBuilds}
                      </Col>
                      <Col>
                        <strong>Failed Builds:</strong> {failedPcBuilds}
                      </Col>
                      <Col>
                        <strong>Highest Streak:</strong> {maxStreak}
                      </Col>
                      <Col>
                        <strong>Final Score:</strong> {score}
                      </Col>
                      <Button
                        variant="danger"
                        onClick={() => {
                          setTimer(180);
                          setScore(0);
                          setPc({
                            platform: "",
                            gpuType: "",
                            generation: "",
                            processor: "",
                            igpu: "",
                            ramType: "",
                            chipset: "",
                            ramRGB: "",
                            ramSize: "",
                            gpuBrand: "",
                            gpuModel: "",
                            cooler: "",
                            primaryStorage: "",
                            secondaryStorage: "",
                            psu: "",
                            cabinetBrand: "",
                            cabinetModel: "",
                            price: 0,
                            features: {
                              processor: [],
                              ram: [],
                              chipset: [],
                              gpu: [],
                              cooler: [],
                              psu: [],
                              primaryStorage: [],
                              secondaryStorage: [],
                              cabinet: [],
                            },
                            tdp: {},
                            benchmarks: {},
                            connectivity: {},
                            expansion: {},
                            warranty: {},
                          });
                          generateBudgetRange(10);
                          setOpenKey(["0"]);
                        }}
                        className="my-3"
                      >
                        Play Again
                      </Button>
                    </Col>
                  )}
                </>
              ) : (
                <>
                  <h1 className="my-4 text-center text-danger">
                    PC Master Hunt
                  </h1>
                  <Card
                    className="p-1 my-1 shadow-sm"
                    style={{ backgroundColor: "#151515" }}
                  >
                    <Card.Body>
                      <Card.Title className="text-danger mb-3 fw-bold text-center">
                        How to Play
                      </Card.Title>
                      <Row className="justify-content-center">
                        <Col
                          xs={12}
                          md={8}
                          className="d-flex justify-content-center"
                        >
                          <ul
                            style={{
                              fontSize: "1.1rem",
                              paddingLeft: "1.5rem",
                              lineHeight: "1.8",
                              color: "#fff",
                              padding: "1rem",
                              borderRadius: "8px",
                            }}
                          >
                            <li style={{ marginBottom: "1rem" }}>
                              You have <b>180 seconds</b> to build as many PCs
                              as possible within the time limit.
                            </li>
                            <li style={{ marginBottom: "1rem" }}>
                              Each round assigns a <b>price range</b>, and your
                              build must stay within it.
                            </li>
                            <li style={{ marginBottom: "1rem" }}>
                              Completing a build within the price range earns
                              you extra time and points.
                            </li>
                            <li style={{ marginBottom: "1rem" }}>
                              Consecutive correct builds increase your{" "}
                              <b>streak bonus</b>.
                            </li>
                            <li style={{ marginBottom: "1rem" }}>
                              Going over the price range resets your streak
                              bonus.
                            </li>
                            <li style={{ marginBottom: "1rem" }}>
                              You must complete a build to unlock the next price
                              range.
                            </li>
                            <li style={{ marginBottom: "1rem" }}>
                              When time runs out, your score is recorded on the
                              leaderboard.
                            </li>
                            <li>
                              Rank high on the leaderboard for a chance to win a{" "}
                              <strong className="text-light">
                                Free Graphics Card!
                              </strong>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                  {highScore && (
                    <Col
                      className="text-center text-danger my-4"
                      style={{ fontSize: "1.1rem" }}
                    >
                      Your Best Score: {highScore} pts.
                    </Col>
                  )}
                  <Col className="text-center mb-4">
                    <Button
                      variant="danger"
                      onClick={() => {
                        setStartFlag("Y");
                        generateBudgetRange(10);
                        window.scrollTo({
                          top: 0, // Scroll to the top
                          behavior: "smooth", // Smooth scroll effect
                        });
                      }}
                    >
                      START
                    </Button>
                  </Col>
                </>
              )}
              <Row className="justify-content-center"></Row>
            </Col>
          </Row>
        </Container>
      )}
    </Container>
  );
};

export default PcMasterGame;
