import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

const Call = () => {
  const location = useLocation();

  if (location.pathname === "/" || location.pathname === "/ad")
    return (
      <div>
        <Link to="/contact-us" className="call_float py-2 px-4">
          Enquire Now
        </Link>
      </div>
    );

  return (
    <Link to="/contact-us" className="call_float_round">
      <i className="fa fa-headphones call-icon" aria-label="Call"></i>
    </Link>
  );
};

export default Call;
