import React, { useState, useEffect } from "react";
import { Modal, Button, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const PCMasterGameModal = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    // Delay modal appearance by 10 seconds (10000ms)
    const timer = setTimeout(() => {
      setShow(true);
    }, 7000);

    // Cleanup timer if user signs in before timeout
    return () => clearTimeout(timer);
  }, []);

  return (
    <Modal show={show} onHide={() => setShow(false)} size="lg" centered>
      <Modal.Header style={{ backgroundColor: "#151515" }}>
        <Col
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Modal.Title className="text-danger">
            Unlock Your Chance to Win a FREE RTX Graphics Card!
          </Modal.Title>
        </Col>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: "#151515" }}>
        <p className="text-light text-center fs-5">
          Ready to level up your gaming setup?
        </p>
        <p className="text-light text-center fs-6">
          Sign in now, play our quick and fun game, and you could walk away with
          a brand-new <span className="text-danger"> RTX Graphics Card!</span> —
          absolutely FREE!
        </p>
        <p className="text-light text-center fs-6">
          Test your PC-building skills and compete with the best for the
          ultimate prize.
        </p>
        <p className="text-danger text-center fw-bold">
          How it works: <br />{" "}
          <span className="text-light">
            1.Sign In/Create Account (It takes 5 seconds!)
            <br /> 2.Play the Game – Test your PC skills in a fast-paced
            challenge.
            <br /> 3.Claim Your Prize – If you reach the top, we’ll ship the RTX
            GPU straight to your door!
          </span>
        </p>
        <Col className="text-center mt-4">
          <Button
            className="mx-3"
            variant="danger"
            as={Link}
            to="/pc-master-game"
            onClick={(e) => {
              setShow(false);
            }}
          >
            Play To Win!
          </Button>
          <Button
            className="mx-3"
            style={{ width: "100px" }}
            variant="secondary"
            onClick={(e) => {
              setShow(false);
            }}
          >
            CLOSE
          </Button>
        </Col>
      </Modal.Body>
    </Modal>
  );
};

export default PCMasterGameModal;
