import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { url } from "../../config";
import { validName, validPhone, validEmail } from "../Regex/Regex";

const ContactUs = () => {
  const istDate = new Date(new Date().getTime() + 5.5 * 60 * 60000);
  const today = istDate.toISOString().split("T")[0];
  const [error, setError] = useState("");
  const [subLoading, setSubLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    date: today,
    email: "",
    message: "",
  });
  const [showAlert, setShowAlert] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    setSubLoading(true);
    e.preventDefault();
    setError("");
    if (!formData.name || !validName.test(formData.name)) {
      setError("Please enter a valid name!");
      setShowAlert(true);
      setTimeout(() => {
        setError("");
        setShowAlert(false);
        setSubLoading(false);
      }, 2000);
      return;
    }
    if (!formData.phone || !validPhone.test(formData.phone)) {
      setError("Please enter a valid 10 digit phone number!");
      setShowAlert(true);
      setTimeout(() => {
        setError("");
        setShowAlert(false);
        setSubLoading(false);
      }, 2000);
      return;
    }
    if (!formData.email || !validEmail.test(formData.email)) {
      setError("Please enter a valid email address!");
      setShowAlert(true);
      setTimeout(() => {
        setError("");
        setShowAlert(false);
        setSubLoading(false);
      }, 2000);
      return;
    }
    if (!formData.date || formData.date < today) {
      setError("Please select a valid future date!");
      setShowAlert(true);
      setTimeout(() => {
        setError("");
        setShowAlert(false);
        setSubLoading(false);
      }, 2000);
      return;
    }
    if (!formData.message) {
      setError("Please enter some additional details!");
      setShowAlert(true);
      setTimeout(() => {
        setError("");
        setShowAlert(false);
        setSubLoading(false);
      }, 2000);
      return;
    }
    if (formData.message.length > 300) {
      setError("Please keep the details under 300 characters!");
      setShowAlert(true);
      setTimeout(() => {
        setError("");
        setShowAlert(false);
        setSubLoading(false);
      }, 2000);
      return;
    }
    sendDetails(formData);
  };

  async function sendDetails(data) {
    try {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "lead_form_submission",
        formType: "callback_Request",
      });
      await axios.post(url + "/callback", { data });
      setError("Your callback request has been submitted successfully!");
      setShowAlert(true);

      setFormData({
        name: "",
        phone: "",
        date: today,
        email: "",
        message: "",
      });
      setTimeout(() => {
        setError("");
        setShowAlert(false);
        setSubLoading(false);
        navigate("/");
      }, 2000);
    } catch (error) {
      console.log(error);
      setSubLoading(false);
    }
  }
  return (
    <div className="bg-black text-white py-5">
      <Container>
        <Row className="justify-content-center">
          <Col lg={6}>
            <h1
              style={{
                fontSize: "2.5rem",
                fontWeight: "bold",
                letterSpacing: "1px",
              }}
              className="text-center text-danger mb-4"
            >
              Request a Call Back
            </h1>
            <Form onSubmit={handleSubmit} className="p-4 rounded shadow-sm">
              <Form.Group controlId="formName" className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your name"
                  name="name"
                  className="bg-white"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formPhone" className="mb-3">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="tel"
                  placeholder="Enter your phone number"
                  name="phone"
                  className="bg-white"
                  maxLength={10}
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formEmail" className="mb-3">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter your email"
                  name="email"
                  className="bg-light"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formDate" className="mb-3">
                <Form.Label>Preferred Date</Form.Label>
                <Form.Control
                  type="date"
                  name="date"
                  className="bg-white"
                  value={formData.date}
                  min={today}
                  placeholder={today}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formMessage" className="mb-3">
                <Form.Label>
                  Additional Details{" "}
                  <span className="text-secondary">
                    ({formData.message.length}/300)
                  </span>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  placeholder="Enter any additional details or questions"
                  name="message"
                  maxLength={300}
                  className="bg-white"
                  value={formData.message}
                  onChange={handleChange}
                />
              </Form.Group>
              {showAlert && (
                <Row>
                  <Col className="h5 text-center mt-3 text-danger">{error}</Col>
                </Row>
              )}
              <Row className="justify-content-center">
                <Col className="h5 mt-3 text-center text-light col-8 col-lg-4">
                  <Button
                    variant="danger"
                    size="lg"
                    onClick={handleSubmit}
                    disabled={subLoading}
                  >
                    <span className="h5">SUBMIT</span>
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ContactUs;
