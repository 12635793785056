import React, { useEffect } from "react";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { url } from "../../config";

const PaymentSuccess = () => {
  let navigate = useNavigate();

  useEffect(() => {
    createOrder();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  axios.defaults.withCredentials = true;

  async function createOrder() {
    try {
      const response = await axios.post(
        url + "/createOrder",
        {},
        { withCredentials: true }
      );
      if (response.status === 201) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "purchase_completed",
          ecommerce: {
            transaction_id: response.data.transaction_id,
            value: response.data.amount,
            currency: response.data.currency || "INR",
          },
        });
      }
      setTimeout(() => navigate("/orders"), 3000);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div className="bg-black">
      <Container className="bg-black pb-5">
        <Container
          className="border border-dark rounded mt-5 p-5 justify-content-center"
          style={{
            backgroundColor: "#151515",
          }}
        >
          <Row className="justify-content-center mt-5">
            <Col className="h1 text-center text-danger col-12">
              Payment Success!
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col className="h5 text-center text-light mt-5 col-10">
              <small>Order Confirmed.</small>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col className="h5 text-center text-light col-10">
              <small>Redirecting to your orders...</small>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
};

export default PaymentSuccess;
